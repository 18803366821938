import {service as request} from '@/utils/request'

// 查询【请填写功能名称】列表
export function listUserScale(query) {
  return request.request({
    url: '/ew/user/scale/list',
    method: 'get',
    params: query
  })
}

// 查询【请填写功能名称】详细
export function getScale(uuid) {
  return request.request({
    url: '/ew/user/scale/' + uuid,
    method: 'get'
  })
}
