<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";

import {listRepo} from "@/api/repo";
export default {
  name: "SaasRepo",
  components: {PanelCard},
  data(){
    return {
      saasList:[],
    }
  },
  async created(){
    this.saasList = (await listRepo()).rows;
  }
}
</script>

<template>
<panel-card  title="SAAS库" type="right-bottom">
  <div class="repo-container">
    <div v-for="(saas,index) in saasList" class="saas-repo" :class="{down:[0,2,3,5].includes(index)}" :key="index">
      <div class="repo-text">{{saas.name}}</div>
    </div>
  </div>
</panel-card>
</template>

<style scoped lang="scss">
.repo-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 384px;
  height: 216px;
  margin: 30px 0 0 106px;

  .saas-repo{
    &.down{
      padding-top:24px;
    }
    width: 112px;
    height: 76px;
    background-image: url("@/assets/images/sass-repo.png") ;
    background-position: bottom;
    background-repeat: no-repeat;
    margin: 0 8px;
    .repo-text{
      color: #FFFFFFA5;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 14px;
      width: 112px;
    }
  }
}
</style>
