<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";
import {listNetScale} from "@/api/netscale";

export default {
  name: "NetScale",
  components: {PanelCard},
  data() {
    return {
      netScaleList: [],
    }
  },
  async created() {
    this.netScaleList = (await listNetScale()).rows;
  }
}
</script>

<template>
  <panel-card title="纳管网络规模" type="right-top">
    <div class="net-scale">
      <div v-for="(item,index) in netScaleList" :key="item.uuid" class="net-scale__item" :class="'back'+ (index+1)">
        <div class="net-scale__item-name">{{ item.dataName }}</div>
        <div class="net-scale__item-data">{{ item.dataScale }}</div>
        <div class="net-scale__item-line"></div>
      </div>
    </div>
  </panel-card>
</template>

<style scoped lang="scss">
.net-scale {
  width: 372px;
  height: 230px;
  margin: 17px 0 0 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &__item {
    height: 40px;
    width: 100%;
    &.back1 {
      background-image: url("@/assets/images/net_scale_1.png");
    }

    &.back2 {
      background-image: url("@/assets/images/net_scale_2.png");
    }

    &.back3 {
      background-image: url("@/assets/images/net_scale_3.png");
    }

    &.back4 {
      background-image: url("@/assets/images/net_scale_4.png");
    }

    background-position: left;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &-name{
      opacity: 0.85;
      color: #FFFFFF;
      font-family: Alibaba PuHuiTi;
      font-weight: regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      padding-left: 70px;

    }

    &-data{
      color: #2AEEFD;
      font-family: DIN Alternate;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0px;
      text-align: right;

    }

    &-line{
      background-image: url("@/assets/images/net_scale_line.png");
      background-position: bottom;
      background-repeat: no-repeat;
      position: absolute;
      width: 100%;
      height: 100%;
      padding-left: 40px;
    }
  }
}

</style>
