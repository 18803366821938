<template>
    <div
        class="panel-card"
        :class="[$props.theme, $props.title ? 'has-title' : '', type]"
    >
        <div v-if="title" class="panel-card__top">
<!--            <img alt="" class="panel-card__logo" src="../../assets/images/dashboard-monitor/title-logo.png" />-->
            <span class="panel-card__title gap">{{ $props.title }}</span>
            <div v-if="$props.suffix || $slots.suffix" class="panel-card__title-splitter"></div>
            <span v-if="$props.suffix && !$slots.suffix" class="panel-card__title-suffix">
                {{ $props.suffix }}
            </span>
            <span v-if="$slots.suffix" class="panel-card__title-suffix">
                <slot name="suffix"></slot>
            </span>
        </div>
        <div class="panel-card__main">
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {
    name: 'PanelCard',
    props: {
        theme: {
            type: String,
            default: 'dark',
        },
        title: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'left-top',
        },
    },
    computed: {
    },
};
</script>

<style scoped lang="scss">
.panel-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index:1;

    //background: var(--background);
    //border-radius: var(--border-radius);
    //box-shadow: var(--box-shadow);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &__top {
        z-index: 1;
        display: flex;
        align-items: center;
        height: 46px;
        padding-left: 54px;
    }

    &__logo {
        width: 40px;
        height: 40px;
        object-fit: fill;
    }

    &__title {
        font-family: PingFang SC;
        font-weight: semibold;
        font-size: 18px;
        line-height: normal;
        letter-spacing: 0px;
        text-align: left;
        color: var(--title-color);

        &-splitter {
            width: 2px;
            height: 12px;
            background-color: var(--title-splitter-color);
            margin: 0 9px
        }

        &-suffix {
            font-size: 16px;
            font-weight: 500;
            color: var(--title-suffix-color);
            display: flex;
            align-items: center;
        }

    }

    &__main {
        flex-grow: 1;
        flex-basis: 0;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: baseline;
        justify-content: start;
        flex-direction: column;
    }

    &.dark {
        --background: rgba(21,45,53,0.5);
        --box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.5);
        --title-splitter-color: #00d1ff;
        --title-color: #02CEFB;
        --title-suffix-color: #fff;
    }
}
.special-func {
   position: relative;
 }
.left-top {
   position: relative;
 }
.left-middle {
   position: relative;
 }
.left-bottom {
   position: relative;
 }
.right-top {
   position: relative;
  .panel-card__top{
    justify-content: right;
    padding-right: 54px;
  }
 }
.right-middle {
   position: relative;
  .panel-card__top{
    justify-content: right;
    padding-right: 54px;
  }
 }
.right-bottom {
   position: relative;
  .panel-card__top{
    justify-content: right;
    padding-right: 54px;
  }
 }
.left-top::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-top-card.png');
  background-size: cover;
  opacity: 0.9;
}
.left-middle::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-middle-card.png');
  background-size: cover;
  opacity: 0.6;
}
.left-bottom::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-bottom-card.png');
  background-size: cover;
  opacity: 0.3;
}
.right-top::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-top-card.png');
  background-size: cover;
  transform: scaleX(-1);
  opacity: 0.9;
}
.right-middle::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-middle-card.png');
  background-size: cover;
  transform: scaleX(-1);
  opacity: 0.6;
}
.right-bottom::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/left-bottom-card.png');
  background-size: cover;
  transform: scaleX(-1);
  opacity: 0.3;
}
.special-func::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/special-func.png');
  background-size: cover;
  opacity: 0.3;
}
</style>
