<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";
import {listSence} from "@/api/sence";

export default {
  name: "ClassicSence",
  components: {PanelCard},
  data() {
    return {
      senceList: [],
      timer: null,
    }
  },
  async created() {
    const senceList = (await listSence()).rows;
    this.senceList = senceList.map((sence, idx) => {
      return {idx: ((idx + 101) + "").substring(1), name: sence.name}
    })
    if (this.senceList.length > 7) {
      this.timer = setInterval(() => {
        const sence = this.senceList.splice(0, 1);
        this.senceList.splice(this.senceList.length - 1, 0, ...sence);
      }, 2000);
    }
  },
  computed: {
    senceShowList() {
      return this.senceList.slice(0,7)
    }
  },
}
</script>

<template>
  <panel-card title="典型应用场景" type="left-bottom">
    <div class="classic-sence">
      <div v-for="(item,index) in senceShowList" :key="item.uuid" class="classic-sence__item"
           :class="'sence-font__' + Math.abs(3 - index)">
        <div class="sence-index">{{ item.idx }}</div>
        <div class="sence-name">{{ item.name }}</div>
      </div>
    </div>
  </panel-card>
</template>

<style scoped lang="scss">
.classic-sence {
  width: 400px;
  height: 230px;
  margin: 17px 0 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #FFFFFF;

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    transition: all 0.5s ease;

    &.sence-font__0 {
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 18px;
      line-height: 26px;
    }

    &.sence-font__1 {
      opacity: 0.8;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 16px;
      line-height: 26px;
    }

    &.sence-font__2 {
      opacity: 0.6;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 14px;
      line-height: 26px;
    }

    &.sence-font__3 {
      opacity: 0.4;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 12px;
      line-height: 26px;
    }

    div {
      margin-right: 12px;
    }

    .sence-index {
      width: 22px;
      text-align: right;
    }

    .sence-name {
      width: 350px;
      text-align: left;
    }
  }
}
</style>
