import {service} from "@/utils/request";
import {getToken} from "@/utils/auth";

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid,
  };
  return service.request({
    url: "/hyperos/login",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}
// 获取key
export function getPublicKey() {
  return service.request({
    url: '/hyperos/login/getPublicKey',
    method: 'get'
  })
}
// 注册方法
export function register(data) {
  return service.request({
    url: "/register",
    headers: {
      isToken: false,
    },
    method: "post",
    data: data,
  });
}

// 获取用户详细信息
export function getInfo() {
  return service.request({
    url: "/api/om/account/getAccountInfo",
    method: "get",
  });
}

export function getUserInfo() {
  return service.request({
    url: '/hyperos/getInfo',
    headers: {
      Authorization: getToken(),
    },
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return service.request({
    url: "/logout",
    method: "post",
  });
}

// 获取验证码
export function getCodeImg() {
  return service.request({
    url: "/captchaImage",
    headers: {
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}
