<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";
import {listQuality} from "@/api/quality";
import {listQualityType} from "@/api/netqualitytype";
import * as echarts from 'echarts';

require('echarts/theme/macarons'); // echarts theme

export default {
  name: "NetQuality",
  components: {PanelCard},
  data() {
    return {
      qualityTabs: [],
      qualityList: [],
      activeTab: -1,
      chartMap: {},
    }
  },
  async created() {
    this.qualityTabs = (await listQualityType()).rows;
    this.qualityList = (await listQuality()).rows;
    this.activeTab = this.qualityTabs[0].id;
    this.qualityTabs.forEach(tab => {
      this.chartMap[tab.id] = echarts.init(this.$refs[`chart${tab.id}`][0], 'macarons');
      this.initChart(this.chartMap[tab.id], tab.id);
    })

  },
  mounted() {
  },
  methods: {
    changeTab(idx) {
      this.activeTab = idx;
    },
    initChart(chart, id) {
      const qualitySubList = this.qualityList.filter(quantity => quantity.busType == id);
      const yLabel = [...new Set(
          qualitySubList.map(quality => {
            return quality.srcName
          })), ""];
      const yAxis = Array(yLabel.length).fill().map((_, index) => index + 1);
      const xLabel = ["", ...new Set(
          qualitySubList.map(quality => {
            return quality.dstName
          }))];
      const xAxis = [0, ...Array(xLabel.length).fill().map((_, index) => index + 1)];
      let tabData = this.qualityTabs.filter(tab => tab.id == id);
      const data = qualitySubList.map(quality => {
        return [xLabel.indexOf(quality.dstName), yLabel.indexOf(quality.srcName), quality.val];
      })
      const level1 = parseFloat(tabData[0].level1)
      const level3 = parseFloat(tabData[0].level3)
      chart.setOption({
        title: {
          text: '业务枢纽',
          top: 22,
          left: 184,
          textStyle: {
            color: "#FFFFFFD8",
            fontFamily: "PingFang SC",
            fontSize: 12,
            lineHeight: 12,
          }
        },
        grid: {
          left: 0,
          bottom: 0,
          right: 1,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxis,
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#275270",
              fontFamily: "PingFang SC",
              opacity: 0.6,
            }
          },
          axisLine: {
            lineStyle: {
              color: "#275270",
              width: 2,
            }
          },
          axisTick: {
            inside: true,
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            color: "#FFFFFFD8",
            margin: 12,
            formatter: function (value, index) {
              return xLabel[index];
            }
          }
        },
        yAxis: {
          type: 'category',
          data: yAxis,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#275270",
              fontFamily: "PingFang SC",
              opacity: 0.6,
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#275270",
              width: 2,
            }
          },
          axisTick: {
            show: false
          },
          interval: 1,
          axisLabel: {
            color: "#FFFFFFD8",
            margin: 12,
            verticalAlign: 'bottom',
            padding: [0, 0, 10, 0],
            formatter: function (value, index) {
              return yLabel[index];
            }
          }
        },
        series: [
          {
            name: 'Punch Card',
            type: 'scatter',
            colorBy: 'data',
            symbolOffset: [0, -12],
            label: {
              fontSize: 12,
              fontWeight: "bold",
              fontFamily: "PingFang SC",
              show: true,
              position: 'inside',
              formatter: function () {
                // const val = params.value;
                return ''
              }
            },
            itemStyle: {
              opacity: 0.8,
              color: function (params) {
                const val = parseFloat(params.value[2]);
                console.log(val)
                if (val <= parseFloat(level1)) {
                  return "#2AEEFD";
                } else if (val > parseFloat(level3)) {
                  return "#E5AF19";
                } else {
                  return "#12BEFE";
                }
              }
            },
            symbolSize: function (val) {
              let ratio = parseFloat(tabData[0].ratio);
              return val[2] / ratio;
            },
            data: data,
            animationDelay: function (idx) {
              return idx * 5;
            }
          }
        ],
      });
    }
  },

}
</script>

<template>
  <div class="container">
    <div class="tabs__header">
      <div class="tabs__header-item" v-for="(tab,idx) in qualityTabs"
           :key="idx" @click="changeTab(tab.id)" :class="{'active':activeTab == tab.id} ">{{ tab.name }}
      </div>
    </div>
    <panel-card title="全局网络质量" type="right-middle">
      <div class="net-quality" v-for="(tab) in qualityTabs" :key="tab.id">
        <div class="net-quality__tab" v-show="tab.id == activeTab">
          <div class="tabs__content" :ref="'chart'+ tab.id"></div>
          <div class="tabs__content-legend">
            <div class="circle1">{{ (parseFloat(tab.level1) == 0 ? '' : '<') + tab.level1 + tab.unit }}</div>
            <div class="circle2">{{
                tab.level2.split(',')[0] + tab.unit
                + '-' + tab.level2.split(',')[1] + tab.unit
              }}
            </div>
            <div class="circle3">{{ '>' + tab.level3 + tab.unit }}</div>
          </div>
        </div>
      </div>

    </panel-card>
  </div>
</template>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100%;
  position: relative;

  .tabs__header {
    position: absolute;
    top: 15px;
    left: 60px;
    display: flex;
    flex-direction: row;
    z-index: 10;

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      width: 48px;
      position: relative;

      color: #FFFFFF72;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: right;
      cursor: pointer;

      &.active {
        color: #10BDF4;

        &::after {
          position: absolute;
          content: "";
          display: inline-block;
          width: 24px; /* 竖线的宽度 */
          height: 2px; /* 竖线的高度，与文字高度一致 */
          background-color: #FFFFFF; /* 竖线的颜色 */
          top: 24px; /* 竖线与文字之间的间距 */
          left: 0px;
          background: #10BDF4;
        }
      }
    }
  }

  .net-quality {
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    position: relative;

    &__tab {
      margin: -12px 0 0 40px;
      color: #FFFFFF;
      width: 368px;
      height: 228px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .tabs__content {
        display: flex;
        flex-direction: row;
        width: 368px;
        height: 228px;
      }

      .tabs__content-legend {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        color: #FFFFFFA5;
        font-family: PingFang SC;
        font-weight: regular;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;

        .circle1 {
          margin-left: 60px;
          margin-right: 20px;

          &::before {
            content: "";
            display: inline-block;
            width: 8px; /* 竖线的宽度 */
            height: 8px; /* 竖线的高度，与文字高度一致 */
            border-radius: 4px;
            background-color: #2AEEFD; /* 竖线的颜色 */
            margin-right: 4px; /* 竖线与文字之间的间距 */
          }
        }

        .circle2 {
          margin-right: 20px;

          &::before {
            content: "";
            display: inline-block;
            width: 8px; /* 竖线的宽度 */
            height: 8px; /* 竖线的高度，与文字高度一致 */
            border-radius: 4px;
            background-color: #12BEFE; /* 竖线的颜色 */
            margin-right: 4px; /* 竖线与文字之间的间距 */
          }
        }

        .circle3 {
          &::before {
            content: "";
            display: inline-block;
            width: 8px; /* 竖线的宽度 */
            height: 8px; /* 竖线的高度，与文字高度一致 */
            border-radius: 4px;
            background-color: #E5AF19; /* 竖线的颜色 */
            margin-right: 4px; /* 竖线与文字之间的间距 */
          }
        }

      }
    }
  }
}
</style>
