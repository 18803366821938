import axios from 'axios';

class Http {
    constructor(options, requestInterceptors, responseInterceptors) {
        this.axios = axios.create(options);
        this.axios.interceptors.request.use(
            (config) => {
                if ( (config.method === 'post' || config.method === 'put')) {
                    const requestObj = {
                        url: config.url,
                        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
                        time: new Date().getTime(),
                    };
                    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
                    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
                    if (requestSize >= limitSize) {
                        console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。');
                        return config;
                    }
                }
                return config;
            },
            (error) => {
                console.log(error);
                Promise.reject(error);
            }
        );
        if (typeof requestInterceptors === 'function') {
            this.axios.interceptors.request.use(requestInterceptors);
        } else if (Array.isArray(requestInterceptors)) {
            requestInterceptors.forEach(interceptor => {
                typeof interceptor === 'function' && this.axios.interceptors.request.use(interceptor);
            });
        }
        this.axios.interceptors.response.use(
            (res) => {
                // 未设置状态码则默认成功状态
                let code = res.status || 200;
                if (res.data.code) {
                    code = res.data.code;
                }
                // 获取错误信息
                // const message = errorCode[code] ?? res.data?.msg ?? errorCode['default'];
                // 二进制数据则直接返回
                if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
                    return res.data;
                }

                if (code === 200) {
                    return res.data;
                }
                return Promise.reject(res.data);

            },
            (error) => {
                const { response } = error;
                let { message } = error;
                if (message === 'Network Error') {
                    message = '后端接口连接异常';
                } else if (message.includes('timeout')) {
                    message = '系统接口请求超时';
                } else if (message.includes('Request failed with status code')) {
                    message = '系统接口' + message.substr(message.length - 3) + '异常';
                } else if (response?.status === 401) {
                    // if (!isRelogin.show) {
                    //     isRelogin.show = true;
                    //     MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
                    //         confirmButtonText: '重新登录',
                    //         cancelButtonText: '取消',
                    //         type: 'warning',
                    //     })
                    //         .then(() => {
                }
                error.message = message;
                return Promise.reject(error);
            }
        );
        if (typeof responseInterceptors === 'function') {
            this.axios.interceptors.response.use(responseInterceptors);
        } else if (Array.isArray(responseInterceptors)) {
            responseInterceptors.forEach(interceptor => {
                typeof interceptor === 'function' && this.axios.interceptors.response.use(interceptor);
            });
        }
    }
    // 辅助函数：过滤掉未传值的参数
    filterParams(params) {
        const filteredParams = {};
        for (const key in params) {
            if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
                filteredParams[key] = params[key];
            }
        }
        return filteredParams;
    }
    request(config) {
        if (config.params) {
            config.params = this.filterParams(config.params);
        }
        return this.axios.request({ ...config });
    }

    get(url, params, config) {
        if (params) {
            params = this.filterParams(params);
        }
        return this.axios.get(url, { params, ...config });
    }

    post(url, data, params, config) {
        return this.axios.post(url, data, { params, ...config });
    }

    put(url, data, params, config) {
        return this.axios.put(url, data, { params, ...config });
    }

    delete(url, params, config) {
        return this.axios.delete(url, { params, ...config });
    }

    patch(url, data, params, config) {
        return this.axios.patch(url, data, { params, ...config });
    }
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = new Http({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 30000,
    showError: true,
});


export { service };
