import {service as request} from '@/utils/request'

// 查询【请填写功能名称】列表
export function listRepo(query) {
  return request.request({
    url: '/ew/repo/list',
    method: 'get',
    params: query
  })
}

// 查询【请填写功能名称】详细
export function getRepo(uuid) {
  return request.request({
    url: '/ew/repo/' + uuid,
    method: 'get'
  })
}
