<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";
import {listFunc} from "@/api/func";

export default {
  name: "SpecialFunc",
  components: {PanelCard},
  data() {
    return {
      funcList: [],
    }
  },
  async created() {
    this.funcList = (await listFunc()).rows;
  },
  methods:{
    openPage(url){
      window.open(url)
    }
  }
}
</script>

<template>
<panel-card  title="特色功能" type="special-func">
  <div class="special-func__container">
    <div v-for="(item,index) in funcList" :key="item.uuid" class="special-func__item"
          :class="'sence-font__' + Math.abs(3 - index)">
      <div class="func-name">{{ item.name }}</div>
      <div class="special-func__item-content">
        <div class="intro">功能介绍</div>
        <div class="link" @click="openPage(item.link)">进入系统</div>
      </div>
    </div>
  </div>
</panel-card>
</template>

<style scoped lang="scss">
.special-func__container {
  width: 687px;
  height: 110px;
  margin: 17px 0 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #FFFFFF;

  & .special-func__item:not(.special-func__item:nth-of-type(1))::before {
    content: "";
    display: inline-block;
    width: 1px; /* 竖线的宽度 */
    height: 60px; /* 竖线的高度，与文字高度一致 */
    background-color: #0C6D9C; /* 竖线的颜色 */
    position: absolute;
    left: -50px;
    top: 25px;
  }

  .special-func__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .func-name{
      color: #2AE4FD;
      font-family: PingFang SC;
      font-weight: semibold;
      font-size: 24px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: left;

    }

    &-content{
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      color: #FFFFFFA5;
      font-family: PingFang SC;
      font-weight: regular;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;

      .intro{
        background-image: url("@/assets/images/intro.png");
        background-position: left;
        background-repeat: no-repeat;
        padding-left: 24px;
        margin: 8px 0;
      }
      .link{
        background-image: url("@/assets/images/link.png");
        background-position: left;
        background-repeat: no-repeat;
        padding-left: 24px;
        margin: 8px 0 0 0;
        cursor: pointer;
      }
    }
  }
}
</style>
