import {getPublicKey, getUserInfo, login} from "@/api/login";
import { getToken, setToken } from "@/utils/auth";
import { newEncrypt } from '@/utils/jsencrypt'
import Cookies from "js-cookie";

const user = {
  namespaced: true,
  state: {
    token: getToken(),
    name: "",
    avatar: "",
    // nickName: '',
    roles: [],
    agreement: false,
    agreementTxid: "",
    blockchainAddress:"",
    permissions: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    // SET_NICKNAME: (state, nickName) => {
    //   state.nickName = nickName;
    // },
    SET_ACCOUNTTYPE: (state, accountType) => {
      state.accountType = accountType;
    },
    SET_ACCOUNTID: (state, accountId) => {
      state.accountId = accountId;
    },
    SET_AGREEMENT: (state, agreement) => {
      state.agreement = agreement;
    },
    SET_AGREEMENTTXID: (state, agreementTxid) => {
      state.agreementTxid = agreementTxid;
    },
    SET_BLOCKCHAINADDRESS: (state, blockchainAddress) => {
      state.blockchainAddress = blockchainAddress;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },

  actions: {
    // 登录
    Login({commit}, userInfo) {

      return new Promise((resolve, reject) => {
        getPublicKey()
            .then(({data}) => {
              let publicKey = data.publicKey
              const username = userInfo.username.trim()
              const password = newEncrypt(userInfo.password, publicKey)
              const code = userInfo.code
              const uuid = userInfo.uuid
              login(username, password, code, uuid)
                  .then(async res => {
                    setToken(res.token)
                    commit('SET_TOKEN', res.token)
                    let res1 = await getUserInfo()
                      Cookies.set('roles', res1.roles.join())
                      Cookies.set('nickName', res1.user.nickName)
                    resolve()
                  })
                  .catch(error => {
                    reject(error)
                  })
            })
            .catch(error => {
              reject(error)
            })
      })
    },
  }
};

export default user;
