import {service as request} from '@/utils/request'

// 查询【请填写功能名称】列表
export function listTotal(query) {
  return request.request({
    url: '/ew/total/list',
    method: 'get',
    params: query
  })
}

// 查询【请填写功能名称】详细
export function getTotal(uuid) {
  return request.request({
    url: '/ew/total/' + uuid,
    method: 'get'
  })
}
