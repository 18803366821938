<script>
import PanelCard from "@/views/dashboard-monitor/panel-card.vue";
import {listComputionScale} from "@/api/computionscale";

export default {
  name: "ComputeScale",
  components: {PanelCard},
  data() {
    return {
      computeScaleList: [],
      computeTotal: 0,
      dataScaleTotal: 0,
      title: ["通算 DC", "智算 DC", "超算 DC", "算力平台"],
      color: [["#19C0FA", "#2882FF"], ["#19FAEC", "#19FA89"], ["#D124FC", "#9024FC"], ["#FAC219", "#FAFA19"]],
    }
  },
  async created() {
    this.computeScaleList = (await listComputionScale()).rows;
    this.computeTotal = this.computeScaleList.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.computePower
    }, 0);
    this.dataScaleTotal = this.computeScaleList.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.dataScale
    }, 0);
  },
}
</script>

<template>
  <panel-card title="纳管算力规模" type="left-middle">
    <div class="compute-scale-container">
      <div class="compute-scale" v-for="(item,index) in computeScaleList" :key="index">
        <div class="compute-scale__title">{{ title[item.dataType - 1] }}</div>
        <div class="compute-scale__data">
          <div class="compute-scale__data-left">
            <div class="compute-scale__data-line">
              <div class="line-back" :style="{background:color[index][0]}"></div>
              <div class="line-front"
                   :style="{background:color[index][0],width: Math.round(item.dataScale / dataScaleTotal * 100) +'%'}"></div>
            </div>
            <div class="compute-scale__data-text">
              <div class="text-left">
                <div class="text-title">规模数</div>
                <div class="text-data" :style="{color:color[index][0]}">{{ item.dataScale }}</div>
              </div>
              <div class="text-right">
                <div class="text-title">占比</div>
                <div class="text-data" :style="{color:color[index][0]}">
                  {{ Math.round(item.dataScale / dataScaleTotal * 100) + "%" }}
                </div>
              </div>
            </div>
          </div>
          <div class="compute-scale__data-right">
            <div class="compute-scale__data-line">
              <div class="line-back" :style="{background:color[index][1]}"></div>
              <div class="line-front"
                   :style="{background:color[index][1],width: Math.round(item.computePower / computeTotal * 100) +'%'}"></div>
            </div>
            <div class="compute-scale__data-text">
              <div class="text-left">
                <div class="text-title">算力({{ item.computePower < 1 ? 'T' : 'P' }})</div>
                <div class="text-data" :style="{color:color[index][1]}">{{ item.computePower < 1 ? parseFloat(item.computePower) * 1024 : item.computePower }}</div>
              </div>
              <div class="text-right">
                <div class="text-title">占比</div>
                <div class="text-data" :style="{color:color[index][1]}">
                  {{ Math.round(item.computePower / computeTotal * 10000) / 100  + "%" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </panel-card>
</template>

<style scoped lang="scss">
.compute-scale-container {
  width: 400px;
  height: 230px;
  display: flex;
  margin-left: 32px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .compute-scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .compute-scale__title {
      height: 22px;
      opacity: 0.85;
      color: #FFFFFF;
      font-family: Alibaba PuHuiTi;
      font-weight: regular;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 2px;

      &::before {
        content: "";
        display: inline-block;
        width: 2px; /* 竖线的宽度 */
        height: 10px; /* 竖线的高度，与文字高度一致 */
        background-color: #FFFFFF; /* 竖线的颜色 */
        margin-right: 10px; /* 竖线与文字之间的间距 */
      }
    }

    .compute-scale__data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      width: 100%;

      .compute-scale__data-left {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .compute-scale__data-text{
          width: 160px;
        }
      }

      .compute-scale__data-right {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .compute-scale__data-text{
          width: 190px;
        }
      }

      .compute-scale__data-line {
        position: relative;
        width: 160px;
        height: 6px;
        margin-bottom: 2px;

        .line-back {
          width: 100%;
          height: 100%;
          opacity: 0.1;
        }

        .line-front {
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: 1;
        }
      }

      .compute-scale__data-text {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .text-left {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .text-right {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .text-title {
          opacity: 0.45;
          color: #FFFFFF;
          font-family: PingFang SC;
          font-size: 14px;
          line-height: 22px;
          padding-right: 4px;
        }

        .text-data {
          font-family: DIN Alternate;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          vertical-align: center;
        }
      }
    }

  }
}
</style>
