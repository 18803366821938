<template>
  <div ref="dashboard" class="dashboard-monitor" :class="[theme]">
    <div class="dashboard-monitor__top">
      <div class="dashboard-monitor__logo">
        <!--        <img src="@/assets/images/logo@1x.png"/>-->
        <!--        <div class="dashboard-monitor__logo-text">未来网络</div>-->
      </div>
      <div class="dashboard-monitor__title-content">
        <div class="title-text">
          <img src="@/assets/images/Title1@2x.png" style="width: 394px;height: 48px;"/>
        </div>

      </div>
    </div>
    <div class="dashboard-monitor__main">
      <div class="dashboard-monitor__main-left">
        <div
            class="dashboard-monitor__user-scale"
        >
          <user-scale></user-scale>
        </div>
        <div class="dashboard-monitor__compute-scale">
          <compute-scale></compute-scale>
        </div>
        <div
            class="dashboard-monitor__classic-sence"
        >
          <classic-sence></classic-sence>
        </div>
      </div>
      <div class="dashboard-monitor__main-middle">
        <div class="dashboard-monitor__overview">
          <div class="dashboard-monitor__overview-left">
            <div class="overview-data">{{ formatNumberWithCommas(overviewRecord.computePower) }}</div>
            <div class="overview-pic"></div>
            <div class="overview-text">算力(PF)</div>
          </div>
          <div class="dashboard-monitor__overview-middle">
            <div class="overview-data">{{ formatNumberWithCommas(overviewRecord.transPower) }}</div>
            <div class="overview-pic"></div>
            <div class="overview-text">运力(TB)</div>
          </div>
          <div class="dashboard-monitor__overview-right">
            <div class="overview-data">{{ formatNumberWithCommas(overviewRecord.memPower) }}</div>
            <div class="overview-pic"></div>
            <div class="overview-text">存力(PB)</div>
          </div>
          <!--          <div class="dashboard-monitor__overview-right">-->
          <!--            <div class="overview-data">{{formatNumberWithCommas(overviewRecord.userScale)}}</div>-->
          <!--            <div class="overview-pic"></div>-->
          <!--            <div class="overview-text">用户(个)</div>-->
          <!--          </div>-->
        </div>
        <div class="dashboard-monitor__map">
          <div class="dashboard-monitor__map-left"></div>
          <div class="dashboard-monitor__map-middle">
            <div class="map">
              <big-map></big-map>
            </div>
            <!--            <div class="map__legend">-->
            <!--              <div class="map__legend-line legend1">安全新总线1.8T</div>-->
            <!--              <div class="map__legend-line legend2">算力区</div>-->
            <!--              <div class="map__legend-line legend3">数据区</div>-->
            <!--              <div class="map__legend-line legend4">算力区枢纽节点</div>-->
            <!--              <div class="map__legend-line legend5">数据区枢纽节点</div>-->
            <!--            </div>-->
          </div>
          <div class="dashboard-monitor__map-right"></div>
        </div>
        <div class="dashboard-monitor__special">
          <special-func></special-func>
        </div>
      </div>

      <div class="dashboard-monitor__main-right">
        <div
            class="dashboard-monitor__net_scale"
        >
          <net-scale></net-scale>
        </div>
        <div
            class="dashboard-monitor__net_quality"
        >
          <net-quality></net-quality>
        </div>
        <div
            class="dashboard-monitor__saas-repo"
        >
          <saas-repo></saas-repo>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {listTotal} from "@/api/total";
import BigMap from "@/views/dashboard-monitor/big-map.vue";
import UserScale from "@/views/dashboard-monitor/user-scale.vue";
import ComputeScale from "@/views/dashboard-monitor/compute-scale.vue";
import ClassicSence from "@/views/dashboard-monitor/classic-sence.vue";
import NetScale from "@/views/dashboard-monitor/net-scale.vue";
import NetQuality from "@/views/dashboard-monitor/net-quality.vue";
import SaasRepo from "@/views/dashboard-monitor/saas-repo.vue";
import SpecialFunc from "@/views/dashboard-monitor/special-func.vue";

export default {
  name: 'DashboardMonitor',
  components: {
    SpecialFunc,
    SaasRepo,
    NetQuality,
    NetScale,
    ClassicSence,
    ComputeScale,
    BigMap,
    UserScale
  },
  data() {
    return {
      theme: 'dark',
      cancelResize: () => {
      },
      deviceList: [],
      currentDeviceIndex: -1,
      // requestNextFrame: new RequestNextFrame(),
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      overviewRecord: {
        computePower: 0,
        memPower: 0,
        transPower: 0,
        userScale: 0,
      }
    };
  },
  async created() {
    this.overviewRecord = (await listTotal()).rows[0];

  },
  mounted() {
    window.onresize = () => {
      this.resize();
    }
    this.resize();
  },
  beforeDestroyed() {
    // this.cancelResize();
    // dataStore.dispose();
  },
  methods: {
    formatNumberWithCommas(number) {
      if (number == null) {
        return "0"
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    resize() {
      this.innerHeight = window.innerHeight;
      this.innerWidth = window.innerWidth;
      document.body.style.zoom = window.innerHeight / 1080;
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-monitor {
  background: var(--bg-color);
  width: 100%;
  //height: 100%;
  min-height: 960px;
  min-width: 1280px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  &__top {
    width: 100%;
    flex-basis: 60px;
    flex-shrink: 0;
    position: relative;
    text-shadow: var(--title-shadow);
    color: var(--title-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 1;
    //margin: 0 20px;
  }

  &__logo {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    left: 24px;
    top: 12px;

    img {
      width: 60px;
      height: 60px;
    }

    &-text {
      margin-left: 12px;
      font-weight: bolder;
      font-size: 18px;
      font-family: "Alibaba PuHuiTi";
      color: red;
    }
  }

  &__title-content {
    background-image: var(--title-bg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 1112px;
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .title-text {
      font-family: PangMenZhengDao;
      font-weight: regular;
      font-size: 42px;
      line-height: normal;
      letter-spacing: 0px;
      text-align: center;
      background: linear-gradient(-180deg, #FFFFFF 0%, #b9e7fc 100%);
      -webkit-background-clip: text; /* 在WebKit浏览器中生效 */
      background-clip: text;
      color: transparent; /* 将文字颜色设为透明，以便显示渐变效果 */
    }
  }

  &__main {
    display: flex;
    position: relative;
    margin-top: -15px;
    background-image: url("@/assets/images/background_highlight@1x.png");
    background-position: bottom;
    background-repeat: no-repeat;

    &-left {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 29%;
    }

    &-middle {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 42%;
      margin-bottom: 20px;
      margin-top: 30px;
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 29%;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  &__user-scale {
    width: 530px;
    height: 315px;
    margin-bottom: 15px;
  }

  &__compute-scale {
    width: 450px;
    height: 315px;
    margin-bottom: 15px;
  }

  &__classic-sence {
    width: 530px;
    height: 315px;
  }

  &__overview {
    display: flex;
    position: relative;
    top: 10px;
    align-items: center;
    justify-content: space-between;
    width: 736px;
    z-index: 1;

    .overview-data {
      color: #2AE4FD;
      font-family: DIN Alternate;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
    }

    .overview-text {
      opacity: 0.65;
      color: #FFFFFF;
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0px;
    }

    .overview-pic {
      width: 72px;
      height: 30px;
      background-image: url("@/assets/images/overview-pic.png");
    }

    &-left {
      position: relative;
      width: 228px;
      height: 108px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/images/overview-left.png');
        background-size: cover;
      }
    }

    &-middle {
      position: relative;
      width: 228px;
      height: 108px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/images/overview-middle.png');
        background-size: cover;
      }
    }

    &-right {
      position: relative;
      width: 228px;
      height: 108px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 228px;
        height: 100%;
        background-image: url('@/assets/images/overview-left.png');
        background-size: cover;
        transform: scaleX(-1);
      }
    }
  }

  &__map {
    display: flex;
    position: relative;
    align-items: center;
    width: 1010px;
    height: 630px;

    &-left {
      position: relative;
      width: 150px;
      height: 570px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/images/left-round.png');
        background-size: cover;
        z-index: 1;
      }
    }

    &-middle {
      position: relative;
      width: 858px;
      height: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: end;

      .map__legend {
        width: 160px;
        height: 128px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-evenly;

        &-line {
          width: 112px;
          height: 16px;
          padding-left: 24px;
          opacity: 0.8;
          color: #FFFFFF;
          font-family: PingFang SC;
          font-weight: regular;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0px;
          text-align: left;
          position: relative;

          &.legend1::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('@/assets/images/map_legend_1@1x.png');
            background-repeat: no-repeat;
            background-size: cover;
          }

          &.legend2::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('@/assets/images/map_legend_2@1x.png');
            background-repeat: no-repeat;
            background-size: cover;
          }

          &.legend3::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('@/assets/images/map_legend_3@1x.png');
            background-repeat: no-repeat;
            background-size: cover;
          }

          &.legend4::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('@/assets/images/map_legend_4@1x.png');
            background-repeat: no-repeat;
            background-size: cover;
          }

          &.legend5::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('@/assets/images/map_legend_5@1x.png');
            background-repeat: no-repeat;
            background-size: cover;
          }

        }
      }

      .map {

      }

    }

    &-right {
      position: relative;
      width: 150px;
      height: 570px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/images/left-round.png');
        background-size: cover;
        transform: scaleX(-1);
      }
    }

  }

  &__special {
    width: 788px;
    height: 200px;
    position: relative;
  }

  &__net_scale {
    width: 530px;
    height: 315px;
    margin-bottom: 15px;
  }

  &__net_quality {
    width: 450px;
    height: 315px;
    margin-bottom: 15px;
  }

  &__saas-repo {
    width: 530px;
    height: 315px;
  }

  &.dark {
    --border-image: linear-gradient(180deg, rgba(0, 209, 255, 1), rgba(0, 165, 255, 1)) 2 2;
    --bg-color: #04112d;
    --title-color: rgba(0, 209, 255, 1);
    --title-bg: url("../../assets/images/header.png");
    --title-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    --main-text-color: #fff;
    --main-color: #00d1ff;
    --box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.5);
    --border-radius: 20px;
    --card-background: rgba(21, 45, 53, 0.5);
    --main-splitter-color: #00d1ff;
    --status-border-color: #0C1119;
    --main-unit-color: #00d1ff;
    --certification-color: #ff5858;
    --kpi-background-color: rgba(0, 0, 0, 0.35);
    --origin-color: #fff;
  }
}
</style>
