<script>
// import * as echarts from 'echarts';
import AMapLoader from '@amap/amap-jsapi-loader';
import {listHubInfo} from "@/api/hubinfo";
import {listNodeInfo} from "@/api/nodeinfo";
import {listNodeRelation} from "@/api/noderelation";
import legend5 from "@/assets/images/map_legend_5_@2x.png"
import legend4 from "@/assets/images/map_legend_4_@2x.png"
// require('echarts/theme/macarons'); // echarts theme
// import 'echarts/extension/bmap/bmap';
export default {
  name: "BigMap",
  data() {
    return {
      map: null,
      loca: null,
      district: null,
      provinces: null,
      polygons: null,
      nodeInfoList: [],
      hubInfoList: [],
      nodeRelationList: [],

    }
  },
  async mounted() {
    // document.querySelector(".map-container").style.width = 1860 * 1080 / window.innerHeight + "px";
    // document.querySelector(".map-container").style.left = -(window.innerWidth * 0.29 + 50) * 1080 / window.innerHeight + "px";
    this.hubInfoList = (await listHubInfo()).rows;
    this.nodeInfoList = (await listNodeInfo()).rows;
    this.nodeRelationList = (await listNodeRelation()).rows;
    this.provinces = this.hubInfoList.flatMap(hubInfo => {
      return hubInfo.hubAreaRelations.map(hub => {
        return {type: hubInfo.type, ...hub};
      });
    })
    this.opened();
  },
  methods: {
    opened: function () {
      if (!this.map) {
        // eslint-disable-next-line no-underscore-dangle
        window._AMapSecurityConfig = {
          securityJsCode: 'da504c973d967bf9cf18d589a84fe4b7',
        };
        AMapLoader.load({
          key: 'da0f693d4cbd92aaa4dda6486b6d231c', // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          // offset: [10, 20], // 定位按钮的停靠位置的偏移量
          zoomToAccuracy: true, //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          Loca: {                // 是否加载 Loca， 缺省不加载
            version: '2.0.0'  // Loca 版本，缺省 1.3.2
          },
        })
            .then((AMap) => {
              console.log('map is loaded');

              const disCountry = new AMap.DistrictLayer({
                zIndex:80,
                depth:0,
                styles: {
                  'stroke-width':1,
                  opacity: 0,
                  fill: '#1b3962',
                  'nation-stroke': '#69a6bf',
                  'coastline-stroke': '#69a6bf',
                  'province-stroke': '#6f92ad',
                }
              })
              this.map = new AMap.Map('main', {
                // 设置地图容器id
                mapStyle: 'amap://styles/darkblue',
                // rotateEnable:true,
                pitchEnable: true,
                zoom: 4.42,
                pitch: 18,
                // rotation: -15,
                viewMode: '3D', //开启3D视图,默认为关闭
                zoomEnable: false,
                dragEnable: false,
                center: [105, 32],
                layers: [disCountry, AMap.createDefaultLayer()]
              });

              // eslint-disable-next-line no-undef
              this.loca = new Loca.Container({
                map: this.map,
              })
              // eslint-disable-next-line no-undef
              // new Loca.AmbientLight({
              //   intensity: 0.8, // 环境光强度，建议值: 0～1
              //   color: '#fff', // 环境光颜色
              // }, this.loca);
// // 平行光
//               // eslint-disable-next-line no-undef
//               new Loca.DirectionalLight({
//                 intensity: 10, // 光照强度，建议值: 0～1
//                 color: 'rgb(255,255,255)', // 平行光颜色
//                 target: [0, 0, 0], // 光射向的目标位置
//                 position: [0, -1, 1], // 坐标位置
//               }, this.loca);
// // 点光
//               // eslint-disable-next-line no-undef
//               new Loca.PointLight({
//                 color: 'rgb(255,255,255)', // 点光颜色
//                 position: [121.50741577148439, 31.236288641793006, 1000], // 点光的位置
//                 intensity: 4, // 光照强度
//                 // 距离表示从光源到光照强度为 0 的位置，0 就是光不会消失。
//                 distance: 1000000,
//               }, this.loca);
              const that = this;
              //------------------国家边线-----------------------
              // AMap.plugin(['AMap.DistrictSearch'], function () {
              //   const district = new AMap.DistrictSearch({
              //     subdistrict: 0,
              //     extensions: 'all',
              //     level: 'country',
              //     showbiz: false,
              //   });
              //   district.search("中国", function (status, result) {
              //     bounds = result.districtList[0].boundaries;
              //
              //     const polygons = [];
              //     bounds.forEach(q => {
              //       const polygon = new AMap.Polygon({
              //         strokeWeight: 1,
              //         path: q,
              //         fillOpacity: 0,
              //         strokeColor: '#69a6bf',
              //         // extrusionHeight:1,
              //         // wallColor:['#2AE4FD' ,'#87d5e0']
              //       });
              //       polygons.push(polygon);
              //     });
              //     that.map.add(polygons);
              //   })
              // });

              //------------------枢纽-----------------------
              var opts = {
                subdistrict: 0,
                extensions: 'all',
                level: 'province',
                showbiz: false, // 是否返回商圈信息

              };
              let bounds;
              const mask = [];
              AMap.plugin(['AMap.DistrictSearch'], function () {
                that.district = new AMap.DistrictSearch(opts);
                that.provinces.forEach(pro => {
                  that.district.search(pro.areaName, function (status, result) {
                    bounds = result.districtList[0].boundaries;

                    that.polygons = [];
                    bounds.forEach(q => {
                      const polygon = new AMap.Polygon({
                        strokeWeight: 0.5,
                        path: q,
                        fillOpacity: 0.2,
                        fillColor: pro.type == 1 ? '#2AE4FD' : '#E5AF19',
                        strokeColor: pro.type == 1 ? '#2AE4FD' : '#E5AF19',
                      });
                      that.polygons.push(polygon);
                    });
                    that.map.add(that.polygons);
                    // that.map.setFitView(that.polygons); // 自适应缩放比

                    for (var i = 0; i < bounds.length; i += 1) {
                      mask.push([bounds[i]])
                    }
                  })
                })
              });
              //------------------节点------------------
              this.nodeInfoList.forEach(nodeInfo => {
                const lnglat = nodeInfo.geo.split(" ");
                // 创建一个 Icon
                var startIcon = new AMap.Icon({
                  // 图标尺寸
                  size: new AMap.Size(32, 32),
                  // 图标的取图地址
                  image: nodeInfo.hubType == 1 ? legend5 : legend4,
                  // 图标所用图片大小
                  imageSize: new AMap.Size(32, 32),
                  // 图标取图偏移量
                  imageOffset: new AMap.Pixel(0, 0)
                });
                const marker = new AMap.Marker({
                  position: lnglat,
                  icon: startIcon,
                  offset: new AMap.Pixel(-16, -16)
                });
                marker.setLabel({
                  direction: !["内蒙古枢纽", "宁夏枢纽", "成渝枢纽","贵州枢纽"].includes(nodeInfo.hubName) ? 'right' : 'left',
                  offset: !["内蒙古枢纽", "宁夏枢纽", "成渝枢纽", "贵州枢纽"].includes(nodeInfo.hubName) ? new AMap.Pixel(10, 10) : new AMap.Pixel(50, -20),  //设置文本标注偏移量
                  content: "<div class='node-text'>" + nodeInfo.hubName + "<br/>" + nodeInfo.name + "</div>", //设置文本标注内容
                });
                that.map.add(marker);

              })

              //------------------总线------------------
              const features = this.nodeRelationList.map(nodeRelation => {
                const from = nodeRelation.fromGeo.split(" ")
                const to = nodeRelation.toGeo.split(" ")
                return {
                  "type": "Feature",
                  "properties": {
                    "country": nodeRelation.toNodeName,
                    "type": 1
                  },
                  "geometry": {
                    "type": "LineString",
                    "coordinates": [from, to]
                  }
                };
              });
              // eslint-disable-next-line no-undef
              const lineGeoMap = new Loca.GeoJSONSource({
                data: {
                  type: "FeatureCollection",
                  features,
                },
              });
              // 弧线
              // eslint-disable-next-line no-undef
              var pulseLink = new Loca.PulseLinkLayer({
                // loca,
                zIndex: 100,
                opacity: 1,
                visible: true,
                zooms: [2, 22],
                depth: true,
              });

              pulseLink.setSource(lineGeoMap);
              pulseLink.setStyle({
                unit: 'px',
                // dash: [40000, 0, 40000, 0],
                lineWidth: function () {
                  return [3, 3];
                },
                height: function (index, feat) {
                  return feat.distance * 1.5;
                },
                // altitude: 1000,
                // smoothSteps: 80,
                speed: function () {
                  return 50;
                },
                flowLength: 38,
                lineColors: function () {
                  return ['#2d7dde'];
                },
                maxHeightScale: 0.5, // 弧顶位置比例
                headColor: '#23d8ec',
                trailColor: '#2578bd',
              });
              this.loca.add(pulseLink);
              this.loca.animate.start();

              // eslint-disable-next-line no-undef
              // var pl = new Loca.PolygonLayer({
              //   zIndex: 120,
              //   cullface: 'none',
              //   shininess: 1,
              //   hasBottom: false,
              //   blockHide: false,
              //   hasSide: true,
              //   hasTop: false,
              //   depth: false,
              // });
              //
              // pl.setSource(lineGeoMap);
              // pl.setStyle({
              //   topColor: function () {
              //     return 'rgba(224,18,18,0)';
              //   },
              //   sideTopColor: function () {
              //     return 'rgba(43,143,143,0)';
              //   },
              //
              //   height: function () {
              //     return 100;
              //   },
              //   altitude: 0,
              // });
              // this.loca.add(pl);

            })
            .catch((e) => {
              console.log(e);
            });
      }
    },

  },
}


</script>

<template>
  <div id="main" class="map-container"></div>
</template>

<style>
.amap-marker-label {
  background: none;
  border: none;
}

.node-text {
  color: #FFFFFF;
  font-family: PingFang SC;
  font-weight: regular;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  width: 100px;
  opacity: 0.8;
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  visibility: hidden !important;
}

</style>
<style scoped lang="scss">
.map-container {
  width: 1860px;
  height: 974px;
  position: absolute;
  left: -556px;
  top: -203px;
}
</style>
