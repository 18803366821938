import {service as request} from '@/utils/request'

// 查询【请填写功能名称】列表
export function listComputionScale(query) {
  return request.request({
    url: '/ew/compution/scale/list',
    method: 'get',
    params: query
  })
}

// 查询【请填写功能名称】详细
export function getComputionScale(uuid) {
  return request.request({
    url: '/ew/compution/scale/' + uuid,
    method: 'get'
  })
}
